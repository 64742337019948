import React, { SFC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Dialog,
    DialogTitle,
    makeStyles,
    DialogContent,
    DialogActions,
    Grid,
    MenuItem,
    FormControl,
    IconButton,
    InputLabel,
    Select,
    TextField,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import {
    Charger,
    ChargerConnector,
    ChargerConnectorState,
    getChargerConnectorSocketTypes,
    getSpecificationForSocketType,
    getConnectorLabel,
    ChargerConnectorAvailability
} from '../../../shared/types/charger'
import { PersistenceState } from '../../../shared/types/common'
import { DialogProgress } from '../../../shared/components/dialogProgress'
import { DialogConfirmButton } from '../../../shared/components/dialogConfirmButton'
import { fetchLocationForDetail, updateConnectorsForCharger } from '../../../redux/actions/locations'

const useStyles = makeStyles((theme) => ({
    dialogContentWrapper: {
        alignItems: 'flex-start',
    },
    dialog: {
        '& .MuiDialog-paper': {
            // this hard-coded width is here because by default, dialog has a min-width set to half. this causes problems
            // when deleting connectors -> dialog gets progresively smaller
            width: '450px',
        },
        '& .MuiFilledInput-root': {
            borderRadius: theme.spacing(0.5),
        },
        '& .MuiFilledInput-root::before': {
            border: 0,
        },
    },
    fullWidth: {
        width: '100%',
    },
    dialogActions: {
        padding: theme.spacing(1, 3, 3, 3),
    },
    deleteButtonWrapper: {
        padding: '0 !important',
        textAlign: 'center',
        alignSelf: 'center',
    },
}))

interface ConnectorsManagementDialogProps {
    open: boolean
    charger: Charger
    onClose(connectors?: ChargerConnector[]): void
}
const ConnectorsManagementDialog: SFC<ConnectorsManagementDialogProps> = ({ open, charger, onClose }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const [connectors, setConnectors] = useState<ChargerConnector[]>(
        charger.connectors
            .sort((x, y) => x.externalId - y.externalId)
            .map((x) => ({
                ...x,
                persistenceState: PersistenceState.Original,
            }))
    )
    const [connectorNewId, setConnectorNewId] = useState<number>(-1)

    const stateSelector = (state: any) => state.locations.chargers.updateConnectors

    const onConnectorDelete = (connector: ChargerConnector) => {
        if (connector.persistenceState === PersistenceState.Added) {
            setConnectors(connectors.filter((x) => x.id !== connector.id))
        } else {
            connector.persistenceState = PersistenceState.Deleted
            setConnectors([...connectors])
        }
    }

    const updateConnector = (connector: ChargerConnector) => {
        if (connector.persistenceState === PersistenceState.Original) {
            connector.persistenceState = PersistenceState.Updated
        }
        setConnectors([...connectors])
    }

    return (
        <Dialog className={classes.dialog} open={open}>
            <DialogProgress stateSelector={stateSelector} />
            <DialogTitle>{t('locations.connectorManagementTitle', { charger: charger.name })}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} className={classes.dialogContentWrapper} alignItems="flex-start">
                    {connectors
                        .filter((x) => x.persistenceState !== PersistenceState.Deleted)
                        .map((connector) => (
                            <React.Fragment key={connector.id}>
                                <Grid item xs={5}>
                                    <FormControl variant="filled" className={classes.fullWidth}>
                                        <InputLabel id={`socketTypeLabel${connector.id}`}>
                                            {t('locations.connector')}
                                        </InputLabel>
                                        <Select
                                            labelId={`socketTypeLabel${connector.id}`}
                                            fullWidth
                                            value={connector.socketType}
                                            onChange={(event) => {
                                                connector.socketType = event.target.value as any
                                                const specification = getSpecificationForSocketType(
                                                    connector.socketType
                                                )
                                                connector.type = specification!.type
                                                updateConnector(connector)
                                            }}
                                        >
                                            {getChargerConnectorSocketTypes(t).map((socketType) => (
                                                <MenuItem key={socketType.id} value={socketType.id}>
                                                    {getConnectorLabel(t, socketType.id, connector.state)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        name="externalId"
                                        fullWidth
                                        required
                                        error={!connector.externalId}
                                        helperText={connector.externalId ? '' : t('common.required')}
                                        inputProps={{
                                            maxLength: 10,
                                            autoComplete: 'off',
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('locations.externalId')}
                                        value={connector.externalId ? connector.externalId : ''}
                                        onChange={(event) => {
                                            const result = event.target.value
                                                ? event.target.value.replace(/[^0-9]/g, '')
                                                : 0
                                            connector.externalId = Number(result)
                                            updateConnector(connector)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2} className={classes.deleteButtonWrapper}>
                                    <IconButton onClick={() => onConnectorDelete(connector)}>
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                            </React.Fragment>
                        ))}
                    <Grid item xs={5}>
                        <FormControl variant="filled" className={classes.fullWidth}>
                            <InputLabel id="addConnectorLabel">{t('locations.addConnector')}</InputLabel>
                            <Select
                                labelId="addConnectorLabel"
                                fullWidth
                                value={''}
                                onChange={(event) => {
                                    setConnectors([
                                        ...connectors,
                                        {
                                            persistenceState: PersistenceState.Added,
                                            id: connectorNewId.toString(),
                                            chargerId: charger.id,
                                            state: ChargerConnectorState.Free,
                                            type: getSpecificationForSocketType(event.target.value as any)!.type,
                                            socketType: event.target.value as any,
                                            externalId: 0,
                                            availability: ChargerConnectorAvailability.Available,
                                            inPreparingState: false,
                                        },
                                    ])
                                    setConnectorNewId(connectorNewId - 1)
                                }}
                            >
                                {getChargerConnectorSocketTypes(t).map((socketType) => (
                                    <MenuItem key={socketType.id} value={socketType.id}>
                                        {getConnectorLabel(t, socketType.id)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    onClick={() => {
                        onClose(undefined)
                    }}
                    color="primary"
                >
                    {t('common.cancel')}
                </Button>
                <DialogConfirmButton
                    label={t('common.save')}
                    stateSelector={stateSelector}
                    onClick={async () => {
                        const result: any = await dispatch(updateConnectorsForCharger(charger, connectors, charger.locationId))
                        await dispatch(fetchLocationForDetail(charger.locationId))
                        if (result) { onClose(result) }
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default ConnectorsManagementDialog
